@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}


:where(.css-dev-only-do-not-override-3mqfnx).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
	background-color: #00A038;
}
:where(.css-dev-only-do-not-override-3mqfnx).ant-menu-light .ant-menu-item-selected {
	color: #001F3F;
	/* font-weight: 650; */
	background: white;
}:where(.css-dev-only-do-not-override-3mqfnx).ant-menu-light {
	color: #A3AED0;
	font-weight: 500;
	opacity: 1;
	font-size: medium;
}
:where(.css-dev-only-do-not-override-3mqfnx).ant-menu-light.ant-menu-inline .ant-menu-item {
	position: relative;
	font-size:small ;
	font-weight: 500;
	letter-spacing: 1px;
}
:where(.css-dev-only-do-not-override-3mqfnx).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
	color: #001F3F;
	/* background-color: rgb(66 42 251 / var(--tw-bg-opacity)); */


}

:where(.css-dev-only-do-not-override-3mqfnx).ant-btn-primary {
	background-color: #00A038;


}
input.defaultCheckbox::before {
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}